<!-- 忘记密码 -->
<template>
  <div class="forget">
    <div class="welcome">
      <div class="title theme-word-color f36 font-b font-bold">忘记密码</div>
      <div class="sub-title f28 color-999">通过邮箱修改Pie universe密码</div>
    </div>
    <div class="content">
      <div class="form-block">
        <div class="form-item">
          <div class="label f30 color-333 font-m require">邮箱</div>
          <div class="input-box">
            <input type="text" placeholder="请输入邮箱" />
          </div>
          <div class="code-block">
            <input type="text" placeholder="邮箱验证码" />
            <div class="border-btn btn">
              <span class="theme-word-color f28">获取</span>
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label f30 color-333 font-m require">新密码</div>
          <div class="input-box">
            <input type="password" placeholder="请输入新密码" />
            <div class="eye">
              <img src="../assets/images/icon-eye.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label f30 color-333 font-m require">确认新密码</div>
          <div class="input-box">
            <input type="password" placeholder="请再次输入新密码" />
            <div class="eye">
              <img src="../assets/images/icon-eye.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="btn-block">
          <div class="btn-register active f32 font-m">确认修改</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
.forget {
  .welcome {
    padding: 1.36rem 0.48rem 0.96rem 0.48rem;
    background: url("../assets/images/login-bg2.png") no-repeat top center;
    background-size: cover;
    text-align: left;
    .title {
      line-height: 0.54rem;
      margin-bottom: 0.1rem;
    }
    .sub-title {
      line-height: 0.4rem;
    }
  }
  .content {
    padding: 0.48rem 0.48rem;
    transform: translateY(-0.48rem);
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    background: #fff;
    .form-block {
      .form-item {
        text-align: left;
        margin-bottom: 0.48rem;
        .label {
          margin-bottom: 0.16rem;
          line-height: 0.4rem;
        }
        .label.require::after {
          content: "*";
          display: inline-block;
          color: #fd4c4c;
          padding-left: 2px;
        }
        .input-box {
          display: flex;
          border: 1px solid #eee;
          padding: 0 0.2rem;
          border-radius: 0.2rem;
          overflow: hidden;
          input {
            flex: 1;
            height: 0.8rem;
            color: #333;
            font-size: 0.28rem;
          }
          .eye {
            width: 0.6rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            img {
              width: 0.6rem;
              height: 0.4rem;
              margin: 0 auto;
            }
          }
        }
        .code-block {
          display: flex;
          justify-content: space-between;
          margin-top: 0.2rem;
          input {
            margin-right: 0.2rem;
            height: 0.8rem;
            flex: 1;
            border: 1px solid #eee;
            border-radius: 0.2rem;
            padding: 0 0.2rem;
            font-size: 0.28rem;
          }
          .border-btn {
            height: 0.8rem;
            line-height: 0.8rem;
            padding: 0 0.2rem;
            min-width: 1.6rem;
            text-align: center;
            border-radius: 0.2rem;
          }
          .border-btn::after {
            border-radius: 0.2rem;
          }
        }
      }
      .btn-block {
        margin-top: 1.4rem;
        .btn-register {
          width: 100%;
          height: 0.8rem;
          line-height: 0.8rem;
          font-size: 0.28rem;
          font-weight: 400;
          margin-bottom: 0.3rem;
          background: #eee;
          border-radius: 0.8rem;
          color: #fff;
        }
        .btn-register.active {
          background-image: linear-gradient(90deg, #5cbffe, #a0f5d0, #ffd7c8);
        }
      }
    }
  }
}
</style>
